import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  public languages: string[] = [];
  public language: string;

  constructor(
    private readonly languageService: LanguageService,
    private readonly router: Router
  ) {}

  public updateLanguage(language) {
    this.router.navigateByUrl(
      this.router.url.replace(this.language, language)
    );
    this.language = language;
    this.languageService.setCurrentLanguage(language);
  }

  ngOnInit() {
    this.language = this.languageService.getCurrentLanguage();
    this.languages = this.languageService.getLanguages();
  }

}
