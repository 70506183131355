import { Component, OnInit } from '@angular/core';
import { LanguageService } from './services/language.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-wrapper',
  templateUrl: './app.wrapper.component.html'
})
export class AppWrapperComponent implements OnInit {

  constructor(
    private readonly languageService: LanguageService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) { }

  private languageCheck() {
    const currentLanguage =  this.activatedRoute.snapshot.params['language'];
    if (this.languageService.isLanguageValid(currentLanguage)) {
      this.languageService.setCurrentLanguage(
        this.activatedRoute.snapshot.params['language']
      );
    } else {
      this.router.navigate([this.languageService.getInitLanguage()]);
    }
  }


  ngOnInit() {
    let  currentLanguage =  this.activatedRoute.snapshot.params['language'];

    if (this.languageService.isLanguageValid(currentLanguage)) {
      this.languageService.setCurrentLanguage(
        this.activatedRoute.snapshot.params['language']
      );
    } else {
      this.router.navigate([this.languageService.getInitLanguage()]);
    }

    this.router.events.subscribe((event) => {
      currentLanguage =  this.activatedRoute.snapshot.params['language'];
      if (event instanceof NavigationEnd && !this.languageService.isLanguageValid(currentLanguage)) {
        this.router.navigate([this.languageService.getInitLanguage()]);
      }
    });
  }

}
