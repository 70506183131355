import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as config from '../../config/config.json';
import { Observable } from 'rxjs/index';

export interface ITenant {
  id?: string;
  name: string;
}

export interface IProduct {
  id: string;
  name: string;
}

export interface ILanguage {
  id?: string;
  name: string;
}


export interface IDoc {
  id?: string;
  name?: string;
  size?: number;
  url?: string;
  type?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private tenantId: string = config.tenantId;

  constructor(private readonly firestore: AngularFirestore) {}

  public getTenant(): Observable<ITenant> {
    return this.firestore.doc<ITenant>(`tenants/${this.tenantId}`).valueChanges();
  }

  public getProducts(): Observable<IProduct[]> {
    return this.firestore
      .collection<IProduct>(`tenants/${this.tenantId}/products`)
      .valueChanges({idField: 'id'});
  }

  public getProductLanguages(id: string): Observable<ILanguage[]> {
      return this.firestore
        .collection<ILanguage>(`tenants/${this.tenantId}/products/${id}/languages`)
        .valueChanges({idField: 'id'});
  }

  public getProductDocs(productId: string, languageId: string): Observable<IDoc[]> {
    return this.firestore
      .collection<IDoc[]>(`tenants/${this.tenantId}/products/${productId}/languages/${languageId}/docs`)
      .valueChanges({idField: 'id'});
  }


}
