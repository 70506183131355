import { Component, OnInit } from '@angular/core';
import { ITenantMetaData, TenantMetaService } from '../services/tenant-meta.service';
import { LanguageService } from '../services/language.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit {

  public companyString: string;
  public legalContent: string;

  private legalType =  this.activatedRoute.snapshot.params['legalType'];

  constructor(
    private tenantMetaService: TenantMetaService,
    private languageService: LanguageService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  private getLegalData(language) {
    this.tenantMetaService.getTenantMetaData(language).subscribe((data: ITenantMetaData) => {
      if (data.legal && data.legal[this.legalType] && data.legal[this.legalType].value) {
        this.legalContent = data.legal[this.legalType].value;
      }
      if (data.company) {
        this.companyString = `© ${new Date().getFullYear()} ${data.company}`;
      }
    });
  }


  ngOnInit() {
    this.getLegalData(this.languageService.getCurrentLanguage());

    this.languageService.watchCurrentLanguage().subscribe((language) => {
      this.getLegalData(language);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.legalType = this.activatedRoute.snapshot.params['legalType'];
        this.getLegalData(this.languageService.getCurrentLanguage());
      }
    });

  }

}
