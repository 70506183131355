import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';

export interface ISidebarItem {
  id: string;
  name: string;
  url: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  public navItems: ISidebarItem[] = [];
  public currentProductId: string;

  constructor(
    private readonly productService: ProductService,
    private readonly  router: Router
  ) {}


  ngOnInit() {
    this.productService.getProducts().subscribe((products) => {
      this.navItems = [];
      Array.from(products)
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((product) => {
        this.navItems.push({
          ...product,
          url: `product/${product.id}`
        });
      });
    });

    this.router.events.subscribe((response) => {
      if (response instanceof ActivationEnd && response.snapshot.params['productId']) {
        this.currentProductId = response.snapshot.params['productId'];
      }
    });
  }

}
