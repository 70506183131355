import { Injectable } from '@angular/core';
import * as config from '../../config/config.json';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private languageSubject: Subject<string>;
  private currentLanguage: string = config.languages[0];

  constructor(private readonly translateService: TranslateService) {
    this.languageSubject = new Subject();
    this.translateService.use(this.currentLanguage);
  }

  public getLanguages(): string[] {
    return config.languages;
  }

  public getInitLanguage(): string {
    return config.languages[0];
  }

  public isLanguageValid(language: string): boolean {
    return this.getLanguages().indexOf(language) !== -1;
  }

  public setCurrentLanguage(language: string) {
    this.currentLanguage = language;
    this.translateService.use(language);
    this.languageSubject.next(language);
  }

  public watchCurrentLanguage(): Subject<string> {
    return this.languageSubject;
  }

  public getCurrentLanguage(): string {
    return this.currentLanguage;
  }

}
