import { Inject, Injectable } from '@angular/core';
import * as config from '../../config/config.json';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

export interface ITenantMetaData {
  id: string;
  company: string;
  theme: {
    id: number;
    version: string;
    baseUri: string;
    cssMinUri: string;
    faviconUri: string;
  };
  legal: {
    imprint: {
      id: number;
      locale: string;
      type: string;
      value: string;
    };
    privacy: {
      id: number;
      locale: string;
      type: string;
      value: string;
    };
    termsOfUse: {
      id: number;
      locale: string;
      type: string;
      value: string;
    };
    contact: {
      id: number;
      locale: string;
      telephone: string;
      email: string;
    }
  };
  defaults: {
    locale: string;
    energyManagerName: string;
  };
}

@Injectable()
export class TenantMetaService {

  private tenant: string;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private readonly http: HttpClient
  ) { }

  public api = `${config.tenantManagerUrl}/api/meta`;

  public setTenant(tenant: string) {
    this.tenant = tenant;
  }

  public getTenantMetaData(language): Observable<ITenantMetaData> {
    return this.http.get<ITenantMetaData>(`${this.api}/${this.tenant}?locale=${language}`);
  }

  public setTheme(theme) {
    this._document.getElementById('favicon').setAttribute('href', theme.faviconUri);
    this._document.getElementById('theme').setAttribute('href', theme.cssMinUri);
  }

}
