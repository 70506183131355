import * as config from '../config/firebase.json';

import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { RouterModule } from '@angular/router';
import { initializer } from './app.init';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TenantMetaService } from './services/tenant-meta.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './services/language.service';
import { ProductModule } from './product/product.module';
import { WelcomeModule } from './welcome/welcome.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ProductService } from './services/product.service';
import { LegalModule } from './legal/legal.module';
import { CoreModule } from './core/core.module';
import { CustomTranslateLoader } from './app.custom-translate-loader';
import { AppWrapperComponent } from './app.wrapper.component';

@NgModule({
  declarations: [
    AppComponent,
    AppWrapperComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes, {useHash: true}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (CustomTranslateLoader.create),
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    AngularFireModule.initializeApp(config.firebase),
    AngularFirestoreModule,
    CoreModule,
    ProductModule,
    WelcomeModule,
    LegalModule
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initializer,
    multi: true,
    deps: [TenantMetaService, ProductService, Title]
  },
    TenantMetaService,
    LanguageService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
