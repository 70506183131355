import { Component, OnInit } from '@angular/core';
import { ITenantMetaData, TenantMetaService } from '../../services/tenant-meta.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  public legal;
  public LEGAL_TYPE_LINK = 'LINK';
  public LEGAL_TYPE_HTML = 'HTML';
  public companyString: string;

  constructor(
    private readonly tenantMetaService: TenantMetaService,
    private readonly languageService: LanguageService
  ) { }

  private updateLegal(language) {
    this.tenantMetaService.getTenantMetaData(language).subscribe((data: ITenantMetaData) => {
      if (data.legal) {
        this.legal = data.legal;
      }
      if (data.company) {
        this.companyString = `© ${new Date().getFullYear()} ${data.company}`;
      }
    });
  }

  ngOnInit() {
    this.updateLegal(this.languageService.getCurrentLanguage());
    this.languageService.watchCurrentLanguage().subscribe((language) => {
      this.updateLegal(language);
    });
  }

}
