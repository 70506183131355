import { Component, OnInit } from '@angular/core';
import { IDoc, ILanguage, ProductService } from '../services/product.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  private productId = this.activatedRoute.snapshot.params['productId'];

  public manuals: IDoc[] = [];
  public supplierDocuments: IDoc[] = [];
  public loading = true;
  public hasData = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly productService: ProductService,
    private readonly languageService: LanguageService,
    private readonly router: Router
  ) {}

  private getDocuments(lang) {
    this.productService.getProductLanguages(this.productId).subscribe((languages) => {
      const items = languages.filter(l => l.name === lang);
      if (items.length > 0 && items[0].id) {
        const currentLanguageId = items[0].id;

        this.productService.getProductDocs(this.productId, currentLanguageId).subscribe((documents: IDoc[]) => {
          this.manuals = documents
            .filter(d => d.type === 'manual')
            .sort((a, b) => b.name.localeCompare(a.name));
          this.supplierDocuments = documents
            .filter(d => d.type === 'supplierDocument')
            .sort((a, b) => a.name.localeCompare(b.name));
          this.hasData = documents.length > 0;
          this.loading = false;
        });
      } else {
        this.manuals = [];
        this.supplierDocuments = [];
        this.hasData = false;
        this.loading = false;
      }
    });
  }

  ngOnInit() {

    this.getDocuments(this.languageService.getCurrentLanguage());

    this.languageService.watchCurrentLanguage().subscribe((currentLanguage) => {
      this.getDocuments(currentLanguage);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loading = true;
        this.productId = this.activatedRoute.snapshot.params['productId'];
        this.getDocuments(this.languageService.getCurrentLanguage());
      }
    });

  }

}
