import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { configLoader } from './app/app.config-loader';
import * as config from './config/config.json';

configLoader.then(conf => {
  try {
    // Imports config correctly after build
    Object.keys(conf).forEach(function (key) {
      config[key] = conf[key];
    });

    enableProdMode();

    platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
  } catch (err) {
    console.log(err);
  }
});
