import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  declarations: [],
  exports: [FooterComponent, HeaderComponent, SidebarComponent],
  imports: [
    CommonModule,
    FooterModule,
    HeaderModule,
    SidebarModule
  ]
})
export class CoreModule { }
