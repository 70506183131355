import { Routes, UrlSegment } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { ProductComponent } from './product/product.component';
import { LegalComponent } from './legal/legal.component';
import { AppWrapperComponent } from './app.wrapper.component';
import * as config from '../config/config.json';

export const getStartPath = () => {
  return  config &&  config.languages &&  config.languages[0] ?  config.languages[0] : '';
};

export const AppRoutes: Routes = [{
    path: ':language',
    component: AppWrapperComponent,
    children: [{
        path: '',
        component: WelcomeComponent
      }, {
        path: 'legal/:legalType',
        component: LegalComponent
      }, {
        path: 'product/:productId',
        component: ProductComponent
      }
    ]}, {
    path: '**',
    redirectTo: getStartPath(),
    pathMatch: 'full'
}];
